import { css, etiColors } from '@eti/styles';
import checkIcon from '@eti/assets/icons/checkList.svg?url'; // eslint-disable-line import/no-unresolved
import { vars } from '@etg/wings';
export const textContent = iconContent => css`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  & p {
    font-size: 0.875rem;

    &.terms-disclaimer {
      margin-bottom: 0;
    }

    @media (min-width: 48em) {
      line-height: 150%;
    }
  }

  & li {
    padding-inline-start: 24px;
    position: relative;

    [dir='rtl'] & {
      padding-left: 0;
    }

    &::before {
      background: url(${checkIcon});
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 16px;
      inset-inline-start: 0;
      margin-inline-end: 8px;
      position: absolute;
      top: 3px;
      width: 16px;
    }
  }

  & a {
    color: ${vars.colors.links.default};
    display: contents;

    &.link {
      display: block;
      margin-bottom: 0;
      width: fit-content;
    }
  }

  & a::after {
    content: ${iconContent !== null && iconContent !== void 0 ? iconContent : ''};
    margin-inline-start: 4px;
    position: relative;
    top: 5px;
  }
`;
export const descriptionStyles = linkIconContent => css`
  color: ${etiColors.black};
  margin-bottom: 0.375rem;
  ${textContent(linkIconContent)};
`;
export const additionalInformationStyles = linkIconContent => css`
  color: ${etiColors.black};
  ${textContent(linkIconContent)};
`;
export const wrapperStyles = css`
  padding-inline: 0;

  ul > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;
export const standardGrid = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);

  .description {
    grid-area: 1 / 1 / 2 / 4;
  }

  .additionalInformation {
    grid-area: 2 / 1 / 3 / 4;
  }

  .promotionalText {
    grid-area: 3 / 1 / 3 / 4;
    margin-top: 8px;
  }

  .children {
    display: contents;
  }
`;